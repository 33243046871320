<script lang="ts">
export const title = useState("accountPageTitle", () => "");
export const description = useState("accountPageDescription", () => "");
</script>

<script setup lang="ts">
import DefaultLayout from "./default.vue";

defineOptions({
  name: "AccountLayout",
});

const { isLoggedIn } = useUser();
const { t } = useI18n();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

useBreadcrumbs([
  {
    name: t("account.account"),
    // @ts-ignore
    path: formatLink("/account"),
  },
]);
</script>

<template>
  <DefaultLayout>
    <div class="custom-container my-10">
      <div v-if="isLoggedIn">
        <div class="hidden sm:block">
          <div class="mb-10">
            <div class="c-scheppach-primary-700 text-[32px] font-700 mb-2">
              {{ title }}
            </div>
            <p v-if="description" class="text-[18px] w-auto xl:w-[950px]">
              {{ description }}
            </p>
          </div>
        </div>
        <!-- Content -->
        <div class="grid grid-cols-1 lg:grid-cols-4 gap-10">
          <div class="col-span-2 xl:col-span-1">
            <AccountNavigation />
          </div>
          <div class="col-span-2 xl:col-span-3">
            <slot></slot>
          </div>
        </div>
      </div>
      <div v-else class="flex items-center justify-center text-7 mt-30">
        {{ t("account.notLoggedIn") }}
      </div>
    </div>
  </DefaultLayout>
</template>
